import React, {useEffect,useState} from "react";
import { API} from 'aws-amplify';
import {useParams} from "react-router-dom";
import {getPlayslide} from '../../graphql/queries';
import {Image} from "react-bootstrap";
import "../css/imgshowroom.css";

function Blackboardshowroom() {
    const [jvfile, setVjfile] = useState();
    const [itt, setItt] = useState("");
    const { id, rid } = useParams();
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        fetchDatas();
    }, []);

    async function fetchDatas() {
        const response = await API.graphql({
            query: getPlayslide,
            variables:{id}
        })
        const datas = response.data.getPlayslide.playlistlinks;
        const newdatas = datas.filter(data => data.rid === rid);
        const lurls = newdatas[0].lurl;
        setVjfile(lurls);
        const tt = newdatas[0].title;
        setItt(tt)
    }

    return (
        <>
            <div className="blackboardshows">
                <Image src={jvfile} fluid className="imgviewsite" alt={itt}/>
            </div>
            </>
    );
}

export default Blackboardshowroom;
