import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getMultioptionslist, listAnswerslists, listLeaderboards} from '../../../graphql/queries';
import {useParams} from "react-router-dom";
import {useForm} from 'react-hook-form';
import "../../css/optionshowroom.css";
import "../../css/multioptions.css";
import {
    createLeaderboard,
    updateLeaderboard,
    createAnswerslist,
    updateAnswerslist, deleteAnswerslist
} from "../../../graphql/mutations";
import Grid from "@material-ui/core/Grid";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import Thiswrong from "../../../img/view/close.png";
import {v4 as uuid} from 'uuid';
import Thiscorrect from "../../../img/view/check-mark.png"
import moment from "moment";
import {newCreateAnswerslist, newDeleteAnswerslist, newUpdateAnswerslist} from "../../../graphql/subscriptions";
import People from "../../../img/overview/people.png";
import Modal from "react-modal";
import Close from "../../../img/host/close.png";
import Submitteds from "../../../img/overview/submit.png"
import Correctimg from "../../../img/view/check-mark.png";
import Wrongimg from "../../../img/view/close.png";
import {Button, Table} from "react-bootstrap";

function Multioptionshowroom() {
    const {register, handleSubmit, errors} = useForm();
    const [quest, setQuest] = useState();
    const [messages, setMessages] = useState();
    const [isShows, setIsShows] = useState(true);
    const [clist, setClist] = useState([]);
    const [playpause, setPlaypause] = useState(true);
    const [timeval, setTimeval] = useState(0);
    const {id, rid} = useParams();
    const [counter, setCounter] = useState();
    const [ans, setAns] = useState([]);
    const [cans, setCans] = useState([]);
    const [isShowcorrects, setIsShowcorrects] = useState(true)
    const [isDisabled, setDisabled] = useState(false);
    const [answersthis, setAnswersthis] = useState([])
    const [thissaved, setThissaved] = useState([]);
    const [submittedmessage, setSubmittedmessage] = useState("");
    const [showclock, setShowclock] = useState(false);
    const [usertackingid, setUsertackingid] = useState("");
    const [thisusername, setThisusername] = useState("");
    const [usertotascore, setUsertotascore] = useState(0);
    const [isShowpeoples, setIsShowpeoples] = useState(false);
    const [peoplenumbers, setPeoplenumbers] = useState(0);
    const [peoples, setPeoples] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [thisid, setThisid] = useState("");
    const [answerednumber, setAnswerednumbers] = useState(0);
    const [thisiscorrect, setThisiscorrect] = useState(0);
    const [thisiswrong, setThisiswrong] = useState(0);
    const [mydata, setMydata] = useState([]);
    const [starttime, setStarttime] = useState("");
    const [userquestions, setUserquestions] = useState([]);
    const d = uuid();
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        fetchDas();
        const submitrids = sessionStorage.getItem("submitrid");
        if (submitrids) {
            setThissaved(submitrids)
            if (submitrids.includes(rid)) {
                setDisabled(true);
                setSubmittedmessage("Submitted")
            }
        }
    }, []);
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        setThisusername(vreachumnewerna);
        if (!vreachumnewerna) {
            setIsShowpeoples(true)
        }
        const reachumnname = localStorage.getItem("vreachumneweremail");
        if (vreachumnewerna) {
            try {
                const input = {
                    id: d,
                    answerid: `2${rid}`,
                    name: vreachumnewerna,
                    email: reachumnname
                }
                API.graphql({
                    query: createAnswerslist,
                    variables: {input}
                });
                setThisid(d)
            } catch (err) {
                console.log(err)
            }
        }
        const nowtime = new Date();
        setStarttime(nowtime)
        const starts = moment().format('MM/DD/YYYY hh:mm a');
        (async () => {
            if (reachumnname) {
                try {
                    await API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: reachumnname
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit: 10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playss = plays.length;
                        if (playss > 0) {
                            const thisid = plays[0].id;
                            setUsertackingid(thisid);
                            sessionStorage.setItem("edureachumthisid", thisid)
                            const thisques = plays[0].questions;
                            if (thisques) {
                                setUserquestions(thisques)
                            }
                            const totalscore = plays[0].score;
                            if (totalscore) {
                                setUsertotascore(totalscore);
                            } else {
                                setUsertotascore(0)
                            }
                        } else {
                            const input = {
                                name: vreachumnewerna,
                                meetemail: reachumnname,
                                answerid: id,
                                score: 0,
                                thisgamescore: 0,
                                timerin: starts
                            }
                            const responsee = API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                            const thistimerid = responsee.id;
                            setUsertackingid(thistimerid);
                            sessionStorage.setItem("edureachumthisid", thistimerid);
                            setUsertotascore(0)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        })()
    }, []);

    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getMultioptionslist,
                variables: {id: rid}
            })
            const datas = response.data.getMultioptionslist;
            const timers = datas.mtimer;
            if (timers !== 0) {
                setCounter(timers);
                setShowclock(true);
            } else {
                setTimeval(40)
            }
            const ques = datas.mquestion;
            setQuest(ques);
            sessionStorage.setItem("edureachumthisquestion", ques)
            const tests = datas.mtest;
            setClist(tests);
            const ct = datas.manswer;
            let cts = [];
            let text = "";
            for (let i = 0; i < ct.length; i++) {
                const t = ct[i].checkans;
                cts.push(t);
                text += tests[Number(t)].panswer + " . "
            }
            setAns(cts);
            setCans(text)

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchData();
        const createanswerslist = API.graphql(
            {
                query: newCreateAnswerslist
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        const updateanswerslist = API.graphql(
            {
                query: newUpdateAnswerslist
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        const deleteanswerslist = API.graphql(
            {
                query: newDeleteAnswerslist
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        return () => {
            createanswerslist.unsubscribe();
            updateanswerslist.unsubscribe();
            deleteanswerslist.unsubscribe()
        }
    }, []);
    const isArrayIncluded = (array1, array2) => {
        return array1.every(item => array2.includes(item));
    }
    const onSubmit = async (data) => {
        let tt = [];
        if (thissaved) {
            tt = [thissaved, rid]
        } else {
            tt = [rid]
        }
        sessionStorage.setItem("submitrid", tt);
        setPlaypause(false);
        const thisdata = data.panswer;
        const vv = thisdata.length;
        const isIncluded = isArrayIncluded(thisdata, ans);
        const thisansl = ans.length;
        const vvperson = Math.round((vv / thisansl) * 100) + "%";
        let countMatchingValues;
        if (isIncluded === true) {
            const rr = thisdata.length;
            const tr = timeval * rr;
            const cm = tr / thisansl;
            countMatchingValues = Math.floor(cm);
        } else {
            countMatchingValues = 0
        }
        if (countMatchingValues > 0) {
            const end = new Date();
            const timeDiff = end - starttime;
            const seconds = parseInt((timeDiff / 1000) % 60);
            const tts = countMatchingValues + usertotascore;
            try {
                const thisqq = {
                    rid: rid,
                    question: quest,
                    correctanswer: "correct",
                    thisscore: countMatchingValues,
                    duration: seconds
                }
                let qq;
                if (userquestions) {
                    qq = [...userquestions, thisqq];
                } else {
                    qq = [thisqq]
                }
                const input = {
                    id: usertackingid,
                    name: thisusername,
                    questions: qq,
                    thisgamescore: countMatchingValues,
                    score: tts
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
                try {
                    const input2 = {
                        id: thisid,
                        aimgurl: vvperson,
                        answers: "submited"
                    }
                    API.graphql({
                        query: updateAnswerslist,
                        variables: {input: input2}
                    })
                } catch (err) {
                    console.log(err)
                }
                setMessages(`You scored ${countMatchingValues} points.`)
            } catch (err) {
                setSubmittedmessage("For the best experience, please use a public browser and a stable internet connection.")
            }
        } else {
            const end = new Date();
            const timeDiff = end - starttime;
            const seconds = parseInt((timeDiff / 1000) % 60);
            try {
                const thisqq = {
                    rid: rid,
                    question: quest,
                    correctanswer: "wrong",
                    thisscore: 0,
                    duration: seconds
                }
                let qq;
                if (userquestions) {
                    qq = [...userquestions, thisqq];
                } else {
                    qq = [thisqq]
                }
                const input = {
                    id: usertackingid,
                    name: thisusername,
                    questions: qq,
                    thisgamescore: 0
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            } catch (err) {
                console.log(err)
            }
            setMessages(`You scored 0 points.`);
            fetchupdatecorrectData();
        }
        setIsShows(false);
        const convertedToNumbers = thisdata.map(Number)
        setAnswersthis(convertedToNumbers);
    };
    const fetchupdatecorrectData = async () => {
        const input1 = {
            id: thisid,
            aimgurl: "wrong",
            answers: "submited"
        }
        await API.graphql({
            query: updateAnswerslist,
            variables: {input: input1}
        })
    }

    const renderTime = ({remainingTime}) => {
        if (remainingTime === 0) {
            setTimeval(0)
            return <div className="timer">0</div>;
        } else {
            setTimeval(remainingTime)
        }
        return (
            <div className="timer">
                <div className="value">{remainingTime}</div>
            </div>
        );
    }

    const getdatas = async () => {
        const mymeetemals = localStorage.getItem("vreachumneweremail");
        if (mymeetemals) {
            try {
                const thisqq = {
                    rid: rid,
                    question: quest,
                    correctanswer: "No answer",
                    thisscore: 0
                }
                let qq;
                if (userquestions) {
                    qq = [...userquestions, thisqq];
                } else {
                    qq = [thisqq]
                }
                const input = {
                    id: usertackingid,
                    name: thisusername,
                    questions: qq,
                    thisgamescore: 0
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            } catch (err) {
                console.log(err)
            }
            setMessages("You scored 0 points")
            setIsShows(false);
            setIsShowcorrects(false)
            setIsShows(false);
        } else {
            setMessages("Time is up.")
            setIsShows(false);
            setIsShowcorrects(false)
        }
    }
    const fetchData = async () => {
        const response = await API.graphql({
            query: listAnswerslists,
            variables: {
                filter: {
                    "answerid": {
                        eq: `2${rid}`
                    }
                },
                limit: 10000
            }
        })

        let filteredData = response.data.listAnswerslists.items;
        const nameid = filteredData.map(item => item.id);
        setMydata(nameid)
        const merged = mergeRecordsByEmail(filteredData);
        setPeoples(merged);
        setPeoplenumbers(merged.length);
        const countAnswered = merged.filter(record => record.answers === "submited").length;
        setAnswerednumbers(countAnswered);
        const countcorrctAnswered = merged.filter(record => record.answers === "submited");
        const iswrongnumber = countcorrctAnswered.filter(record => record.aimgurl === "wrong").length;
        const iscorrectnumber = Number(countAnswered - iswrongnumber);
        setThisiscorrect(iscorrectnumber);
        setThisiswrong(iswrongnumber);
    }
    const mergeRecordsByEmail = (records) => {
        const mergedRecords = {};
        records.forEach(record => {
            const {email, answerid, answers, aimgurl} = record;
            if (!mergedRecords[email]) {
                mergedRecords[email] = {
                    email,
                    name: record.name,
                    answerid: null,
                    answers: null,
                    aimgurl: null
                };
            }
            if (answers === "submited") {
                mergedRecords[email].answerid = answerid;
                mergedRecords[email].answers = answers;
                mergedRecords[email].aimgurl = aimgurl;
            } else if (mergedRecords[email].answerid === null) {
                mergedRecords[email].answerid = answerid;
            }
        });

        // Return merged records as an array
        return Object.values(mergedRecords);
    };

    function removealldatas() {
        let text = "Are you sure you want to delete this data? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            const deleteMultipleEntries = async (ids) => {
                try {
                    for (let id of mydata) {
                        const input = {id};
                        await API.graphql({
                            query: deleteAnswerslist,  // Your GraphQL mutation to delete an item
                            variables: {input}
                        });
                    }
                } catch (error) {
                    console.error("Error deleting entries:", error);
                }
            };
            deleteMultipleEntries(mydata);
        }

    }

    useEffect(() => {
        let start;
        const handleStartTime = () => {
            start = new Date();
        };
        const onClose = () => {
            const thisid = sessionStorage.getItem("edureachumthisid");
            const ends = moment().format('MM/DD/YYYY hh:mm a');
            try {
                const input = {
                    id: thisid,
                    timerout: ends
                }
                API.graphql({
                    query: updateLeaderboard,
                    variables: {input}
                });
            } catch (err) {
                console.log(err)
            }
        }
        window.addEventListener('load', handleStartTime);
        window.addEventListener('beforeunload', onClose)
        window.addEventListener('blur', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            window.removeEventListener('load', handleStartTime);
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('blur', onClose);
            window.removeEventListener('pagehide', onClose);
        }
    }, []);
    return (
        <>
            <div className="drawoptionpage2">
                <div className="greenback">
                    <Grid container spacing={0}>
                        <Grid item xs={2}>
                            {showclock ?
                                <div className="lpollheadclock2">
                                    <CountdownCircleTimer
                                        isPlaying={playpause}
                                        duration={counter}
                                        size={50}
                                        colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                                        colorsTime={[7, 5, 2, 0]}
                                        onComplete={getdatas}
                                    >
                                        {renderTime}
                                    </CountdownCircleTimer>
                                </div>
                                : ""
                            }
                        </Grid>
                        <Grid item xs={10}>
                            <div className="fastanswes">
                                <h2>{quest}</h2>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    {isShowpeoples ?
                        <div className="peoplesview">
                            <img src={People} onClick={() => setShowPopup(true)}/>
                        </div>
                        : ""
                    }
                </div>
                <br/>
                <div className="drawoptioninsidepage">
                    {isShows ?
                        <>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="reachumcheckbox-container">
                                    {
                                        clist.map((item, index) => {
                                                return (
                                                    <>
                                                        <label key={index} className="reachumcheckbox-label">
                                                            <input
                                                                type="checkbox"
                                                                value={item.id}
                                                                name="panswer"
                                                                ref={register({required: 'Please select'})}
                                                                style={{
                                                                    marginRight: "15px",
                                                                    transform: "scale(2)",
                                                                    cursor: "pointer"
                                                                }}
                                                            />
                                                            {item.panswer}
                                                        </label>
                                                    </>
                                                )
                                            }
                                        )
                                    }
                                </div>
                                <br/>
                                <br/>
                                <div className="rightsidethis">
                                    <p className="redthis"> {submittedmessage} </p>
                                    <input type="submit" className="inputpolllist" disabled={isDisabled}
                                           value="Submit"/>
                                </div>
                                <br/>
                                <br/>
                            </form>
                        </> :
                        <>
                            {isShowcorrects ?
                                <div className="reachumcheckbox-container">
                                    <ul className="nonheaderul">
                                        {
                                            clist.map((item, index) => {
                                                    const convertedTo = ans.map(Number)
                                                    const shouldBeChecked = convertedTo.includes(index)
                                                    const isChecked = answersthis.includes(index)
                                                    const icon = shouldBeChecked
                                                        ? `${Thiscorrect}`
                                                        : isChecked
                                                            ? `${Thiswrong}`
                                                            : null
                                                    return (
                                                        <>
                                                            <li key={index} className="reachumcheckbox-label">
                                                                <img className="correctandincorrect" src={icon}
                                                                     width="20px"/>
                                                                {item.panswer}
                                                            </li>
                                                        </>
                                                    )
                                                }
                                            )
                                        }
                                    </ul>
                                </div>
                                : ""
                            }
                            <div className="multicentermessage">
                                <br/>
                                <p className="redthis">{messages}</p>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        </>
                    }
                </div>
            </div>
            <Modal isOpen={showPopup} ariaHideApp={false}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <div className="leftside">
                            <Button onClick={removealldatas} variant="outline-secondary" size="sm"> Clear </Button>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="rightside">
                            <img src={Close} onClick={() => setShowPopup(false)} alt="Close"/>
                        </div>
                    </Grid>
                </Grid>
                <div className="cccenter">
                    <h3><img src={People}/> <span className="redthis">{peoplenumbers}</span> &nbsp; <img
                        src={Submitteds}/>
                        <span className="redthis">{answerednumber}</span> &nbsp; <img
                            src={Correctimg} width="25px"/> <span className="redthis">{thisiscorrect}</span> &nbsp; <img
                            src={Wrongimg} width="18px"/> <span className="redthis">{thisiswrong}</span>
                    </h3>
                    <br/>
                </div>
                <div>
                    <Table hover size="sm" className="sizeforbtable">
                        <thead>
                        <tr>
                            <th> Name</th>
                            <th className="cccenter"> Correct / Incorrect</th>
                            <th className="cccenter"> % Correct</th>
                        </tr>
                        </thead>
                        <tbody>
                        {peoples.map((record, index) => (
                            <tr
                                key={index}
                                style={{
                                    color: record.answers === null ? '#000000' : '#000000', // Apply red color if answers is null
                                    marginBottom: '10px',
                                }} className="peopleviewsconitem">
                                <td className="leftsitethis">{record.name}</td>
                                <td>
                                    {record.answers === "submited" && record.aimgurl !== "wrong" ? (
                                        <img src={Correctimg} width="20px"/>
                                    ) : record.answers === "submited" && record.aimgurl === "wrong" ? (
                                        <img src={Wrongimg} width="16px"/>
                                    ) : (
                                        <span></span>
                                    )}
                                </td>
                                <td>
                                    {record.answers === "submited" && record.aimgurl !== "wrong" ? (
                                        <span>{record.aimgurl}</span>
                                    ) : record.answers === "submited" && record.aimgurl === "wrong" ? (
                                        <span>0%</span>
                                    ) : (
                                        <span></span>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </Modal>
        </>
    )
}

export default Multioptionshowroom;
