import React, {useEffect, useState} from "react";
import { API} from 'aws-amplify';
import {getTextslide} from '../../graphql/queries';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {useParams} from "react-router-dom";
import "../css/slide.css";


function Newershowroom() {
    const [clist, setClist] = useState("");
    const { id, rid } = useParams();
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        fetchDas();
    }, []);

    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getTextslide,
                variables: {id:rid}
            })
            const datas = response.data.getTextslide;
            const tests = datas.bgcolor;
            if(tests !== null) {
                setClist(tests);
            }
        }catch(err){
            console.log(err)
        }
    }

    return (
        <>
            <div className="newertextshowpage">
                <SunEditor hideToolbar={true} disable={true} setDefaultStyle="font-size: 24px;" setContents={clist} width="100%" height="100%" />
            </div>
        </>
    )
}

export default Newershowroom;
