import React, {useState} from "react";
import {API} from 'aws-amplify';
import {listHosts} from '../../graphql/queries';
import "../css/leaderboard.css";
import {Helmet} from "react-helmet";
import Pmorereports from "./leaderboard/sharemorereports";
import {Button, Modal} from 'react-bootstrap';


function ShareReports() {
    const [showthis, setShowthis] = useState(true);
    const [val, setVal] = useState("")
    const [openreport, setOpenreport] = useState(true);
    const [thisisowner, setThisisowner] = useState("");
    const [ message, setMessage] = useState("");
    async function sendmatch() {
                const query = new URLSearchParams(window.location.search);
                const q = query.get("p");
                if(q) {
                    const response = await API.graphql({
                        query: listHosts,
                        variables: {
                            filter: {
                                "sharecode": {
                                    eq: q
                                }
                            },
                            limit: 10000
                        }
                    })
                    const datas = response.data.listHosts.items;
                    console.log("datas", datas)
                    if (datas.length > 0) {
                        const tt = datas[0].shareemail;
                        const foundEmail = tt.find(item => item.email === val);
                        if(!foundEmail){
                            setMessage("Please enter your correct email.")
                            setOpenreport(false);
                        }else{
                            setShowthis(false)
                        }
                        const thisowner = datas[0].owner;
                        console.log("thisowner", thisowner)
                        setThisisowner(thisowner);
                    } else {
                        setOpenreport(false)
                    }
                }else{
                    setOpenreport(false)
                }
        }

    const handleChange = e => {
        const tt = e.target.value
        setVal(tt);
    }
 function submitthis(){
     return sendmatch()
    }
    return (
        <>
            <Helmet>
                <title>Share Reports</title>
            </Helmet>
            {openreport ?
                        <>
                            <div className="leaderpage">
                                <div className="cccenter">
                                    <br/>
                                    <h3> Reports </h3>
                                    <br/>
                                            <h4 className="newinviteradiolabel">
                                                Lesson Log
                                            </h4>
                                    <br/>
                                        <Pmorereports data={thisisowner}/>
                                </div>
                            </div>
                            <Modal show={showthis} size="lg" centered>
                                <Modal.Body>
                                    <div className="enteryournameform">
                                        <div className="cccenter">
                                            <h3>Enter your Email</h3>
                                            <br/>
                                            <input type="email" placeholder="Email" onChange={handleChange} className="sendreportform" />
                                            <p className="redthis">{message}</p>
                                            <br/>
                                            <Button onClick={submitthis} className="copys"> View Reports</Button>
                                            <br/>
                                            <br/>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </>
                     :
                <div className="cccenter">
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <h1 className="grays">This link has expired or the email you entered is incorrect.</h1>
                </div>
            }

        </>
    )
}

export default ShareReports;
