import React, {useEffect,useState} from "react";
import { API} from 'aws-amplify';
import {getMultioptionsdesmoslist} from "../../../../graphql/queries";
import {useParams} from "react-router-dom";
import "../../../css/correctanswerpage.css";

function Multidesmoscorrctanswer() {
    const [ans, setAns] = useState("");
    const [cans, setCans] = useState([]);
    const [thisimage, setThisimage] = useState("");
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDatas();
    }, []);
    async function fetchDatas() {
        const response = await API.graphql({
            query: getMultioptionsdesmoslist,
            variables:{id:rid}
        })
        const datas = response.data.getMultioptionsdesmoslist;
        const aa = datas.mquestion;
        setAns(aa)
        const tests = datas.mtest;
        const ct = datas.manswer;
        const mimage = datas.image;
        if(mimage !== "") {
            setThisimage(mimage);
        }
        const mergedArray = [];

        for (let i = 0; i < Math.max(tests.length, ct.length); i++) {
            const obj1 = tests[i] || {};
            const obj2 = ct[i] || {};

            const mergedObj = { ...obj1, ...obj2 };
            mergedArray.push(mergedObj);
        }
        const filteredArray = mergedArray.filter(item => item.checkans === 'true');
        setCans(filteredArray);
    }

    const displayedItems = cans.map(item => (
        <div key={item.id}>
            <span> {item.panswer} </span>
            {item.mathstate !== null && (
                <img src={item.mathstate} className="borderthis" alt="graphics" />
            )
            }
            <br/>
            <br/>
        </div>
    ));
    return (
            <div className="correctanswerpage">
            <div className="corrctcentermath">
                <br/>
                <h2>{ans}</h2>
                <img src={thisimage} className="fillinthimgshow" />
                <br/>
                <br/>
                <p>The correct answer is: </p>
                <div className="redthis">
                    {displayedItems}
                </div>
                <br/>
                <br/>
                <br/>
            </div>
            </div>
    );
}

export default Multidesmoscorrctanswer;
