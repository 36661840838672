import React, {useRef, useEffect, useState} from "react";
import { API } from 'aws-amplify';
import {getPlayslide} from '../../../graphql/queries';
import {useParams} from "react-router-dom";
import "../../css/imgshowroom.css";

function Mp3room() {
    const [jvfile, setVjfile] = useState();
    const { id, rid } = useParams();
    const audioRef = useRef(null);
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        fetchDatas();
    }, []);

    async function fetchDatas() {
        const response = await API.graphql({
            query: getPlayslide,
            variables: {id}
        });
        const datas = response.data.getPlayslide.playlistlinks;
        const newdatas = datas.filter(data => data.rid === rid);
        const vbucket = newdatas[0].file.bucket;
        const vkey = newdatas[0].file.key;
        const rurls = `https://${vbucket}.s3.amazonaws.com/public/${vkey}`;
        setVjfile(rurls);
    }

    return (
        <>
            <div className="imgshows">
                <audio ref={audioRef} src={jvfile} controls/>
            </div>
        </>
    );
}

export default Mp3room;
