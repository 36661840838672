import React, {useState, useEffect} from 'react'
import Showcategory from './showcategory'
import {API} from "aws-amplify";
import {getDraganddrop, listAnswerslists, listLeaderboards} from "../../../graphql/queries";
import {Button, Table} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../css/taptap.css";
import moment from "moment";
import {v4 as uuid} from 'uuid';
import {createAnswerslist, createLeaderboard, deleteAnswerslist} from "../../../graphql/mutations";
import Correctimg from "../../../img/view/check-mark.png";
import Wrongimg from "../../../img/view/close.png";
import Modal from "react-modal";
import {newCreateAnswerslist, newDeleteAnswerslist, newUpdateAnswerslist} from "../../../graphql/subscriptions";
import Grid from "@material-ui/core/Grid";
import Close from "../../../img/host/close.png";
import People from "../../../img/overview/people.png";
import Submitteds from "../../../img/overview/submit.png";

export default function Showdragdrop() {
  const [isgameopen, setIsgameopen] = useState(false)
  const [quest, setQuest] = useState();
  const { id, rid } = useParams();
  const [isShowpeoples, setIsShowpeoples] = useState(false);
  const [peoplenumbers, setPeoplenumbers] = useState(0);
  const [peoples, setPeoples] = useState([]);
  const [mydata, setMydata] = useState([])
  const [thiscate, setThiscate] = useState([]);
  const [counter, setCounter] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [thisiscorrect, setThisiscorrect] = useState(0);
  const [answerednumber, setAnswerednumbers] = useState(0);
  const [thisiswrong, setThisiswrong] = useState(0);
  const starts = moment().format('dddd  MMMM Do YYYY  h:mm:ss a');
  sessionStorage.setItem("edureachumthisstartstimer", starts)
  const start = new Date();
  const d = uuid();
  sessionStorage.setItem("edureachumthisstarttimer", start.toString())
  useEffect(() => {
    fetchDas();
  }, []);
  useEffect(() => {
    const vreachumnewerna = localStorage.getItem("vreachumnewername");
    const reachumnname = localStorage.getItem("vreachumneweremail");
    if (!vreachumnewerna) {
      setIsShowpeoples(true);
    }
    if (vreachumnewerna) {
      try {
        const input = {
          id: d,
          answerid: `2${rid}`,
          name: vreachumnewerna,
          email: reachumnname
        }
        API.graphql({
          query: createAnswerslist,
          variables: {input}
        });
        sessionStorage.setItem("thisflipshowroomreachumid", d)
      } catch (err) {
        console.log(err)
      }
    }
    (async () => {
      if (reachumnname) {
        try {
          await API.graphql({
            query: listLeaderboards,
            variables: {
              filter: {
                "meetemail": {
                  eq: reachumnname
                },
                "answerid": {
                  eq: id
                }
              },
              limit: 10000
            }
          }).then(datas => {
            const plays = datas.data.listLeaderboards.items;
            const playss = plays.length;
            if (playss > 0) {
              const thisid = plays[0].id;
              sessionStorage.setItem("edureachumthisid", thisid)
              const thisques = plays[0].questions;
              if(thisques) {
                const serializedArray = JSON.stringify(thisques);
                sessionStorage.setItem("edureachumthisques", serializedArray)
              }
              const totalscore = plays[0].score;
              if(totalscore) {
                sessionStorage.setItem("edureachumthistotalscore", totalscore)
              }else{
                sessionStorage.setItem("edureachumthistotalscore", 0)
              }
              sessionStorage.setItem("edureachumthisuser", vreachumnewerna)
            }else{
              const input = {
                name: vreachumnewerna,
                meetemail: reachumnname,
                answerid: id,
                score: 0,
                thisgamescore: 0,
                timerin:starts
              }
              const responsee = API.graphql({
                query: createLeaderboard,
                variables: {input}
              });
              const thistimerid = responsee.id;
              sessionStorage.setItem("edureachumthisid", thistimerid);
              sessionStorage.setItem("edureachumthistotalscore", 0)
            }
          })
        } catch (err) {
          console.log(err)
        }
      }
    })()
  }, []);
  async function fetchDas() {
    try {
      const response = await API.graphql({
        query: getDraganddrop,
        variables: {id:rid}
      })
      const datas = response.data.getDraganddrop;
      const timers = datas.dtimer;
      setCounter(timers)
      const ques = datas.dquestion;
      setQuest(ques);
      sessionStorage.setItem("edureachumthisquestion", ques)
      const caes = datas.categories;
      setThiscate(caes);
    }catch(err){
      console.log(err)
    }
  }
  function savethisdata(){
    sessionStorage.setItem("dragdropcategories",JSON.stringify(thiscate));
    sessionStorage.setItem("dragdropcategoriescounter", counter);
    sessionStorage.setItem("dragdropcategoriesques", quest);
    setIsgameopen(true);
  }
  useEffect(() => {
    fetchData();
    const createanswerslist = API.graphql(
        {
          query: newCreateAnswerslist
        }
    ).subscribe({
      next: () => {
        fetchData();
      }
    })
    const updateanswerslist = API.graphql(
        {
          query: newUpdateAnswerslist
        }
    ).subscribe({
      next: () => {
        fetchData();
      }
    })
    const deleteanswerslist = API.graphql(
        {
          query: newDeleteAnswerslist
        }
    ).subscribe({
      next: () => {
        fetchData();
      }
    })
    return () => {
      createanswerslist.unsubscribe();
      updateanswerslist.unsubscribe();
      deleteanswerslist.unsubscribe()
    }
  }, []);
  const fetchData = async () => {
    const response = await API.graphql({
      query: listAnswerslists,
      variables: {
        filter: {
          "answerid": {
            eq: `2${rid}`
          }
        },
        limit: 10000
      }
    })

    let filteredData = response.data.listAnswerslists.items;
    const nameid = filteredData.map(item => item.id);
    setMydata(nameid)
    const merged = mergeRecordsByEmail(filteredData);
    setPeoples(merged);
    setPeoplenumbers(merged.length);
    const countAnswered = merged.filter(record => record.answers === "submited").length;
    setAnswerednumbers(countAnswered);
    const countcorrctAnswered = merged.filter(record => record.answers === "submited");
    const iswrongnumber = countcorrctAnswered.filter(record => record.aimgurl === "wrong").length;
    const iscorrectnumber = Number(countAnswered - iswrongnumber);
    setThisiscorrect(iscorrectnumber);
    setThisiswrong(iswrongnumber);
  }
  const mergeRecordsByEmail = (records) => {
    const mergedRecords = {};
    records.forEach(record => {
      const {email, answerid, answers, aimgurl} = record;
      if (!mergedRecords[email]) {
        mergedRecords[email] = {
          email,
          name: record.name,
          answerid: null,
          answers: null,
          aimgurl:null
        };
      }
      if (answers === "submited") {
        mergedRecords[email].answerid = answerid;
        mergedRecords[email].answers = answers;
        mergedRecords[email].aimgurl = aimgurl;
      } else if (mergedRecords[email].answerid === null) {
        mergedRecords[email].answerid = answerid;
      }
    });

    // Return merged records as an array
    return Object.values(mergedRecords);
  };

  function removealldatas() {
    let text = "Are you sure you want to delete this data? \nEither OK or Cancel.";
    if (window.confirm(text) === true) {
      const deleteMultipleEntries = async () => {
        try {
          for (let id of mydata) {
            const input = {id};
            await API.graphql({
              query: deleteAnswerslist,  // Your GraphQL mutation to delete an item
              variables: {input}
            });
          }
        } catch (error) {
          console.error("Error deleting entries:", error);
        }
      };
      deleteMultipleEntries(mydata);
    }

  }
  return (
      <>
      <div>
              {isgameopen ?
                  <>
                 <Showcategory />
                  </>
                  :
                  <div className="cccenter">
                    <br/>
                    <br/>
                    <br/>
                    <h2> Tap Tap </h2>
                    <br/>
                    <p className="biggertext">
                      Tap to select an item.</p>
                    <p className="biggertext"> Then tap the category where it belongs.
                    </p>
                    <div>
                      {isShowpeoples ?
                          <div>
                            <img src={People} onClick={() => setShowPopup(true)}/>
                          </div>
                          : ""
                      }
                    </div>
                    <br/>
                    <Button onClick={savethisdata}>CLICK TO START</Button>
                  </div>
              }
      </div>
        <Modal isOpen={showPopup} ariaHideApp={false}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="leftside">
                <Button onClick={removealldatas} variant="outline-secondary" size="sm"> Clear </Button>
              </div>
            </Grid>
            <Grid item xs={6}>
        <div className="rightside">
          <img src={Close} onClick={() => setShowPopup(false)} alt="Close"/>
        </div>
      </Grid>
    </Grid>
    <div className="cccenter">
      <h3><img src={People} /> <span className="redthis">{peoplenumbers}</span> &nbsp; <img src={Submitteds} /> <span className="redthis">{answerednumber}</span> &nbsp; <img
          src={Correctimg} width="25px" /> <span className="redthis">{thisiscorrect}</span> &nbsp; <img src={Wrongimg} width="18px"/> <span className="redthis">{thisiswrong}</span>
      </h3>
      <br/>
    </div>
    <div>
      <Table hover size="sm" className="sizeforbtable">
        <thead>
        <tr>
          <th> Name</th>
          <th className="cccenter"> Correct / Incorrect</th>
          <th className="cccenter"> % Correct </th>
        </tr>
        </thead>
        <tbody>
        {peoples.map((record, index) => (
            <tr
                key={index}
                style={{
                  color: record.answers === null ? '#000000' : '#000000', // Apply red color if answers is null
                  marginBottom: '10px',
                }} className="peopleviewsconitem">
              <td className="leftsitethis">{record.name}</td>
              <td>
                {record.answers === "submited" && record.aimgurl !== "wrong" ? (
                    <img src={Correctimg} width="20px"/>
                ) : record.answers === "submited" && record.aimgurl === "wrong" ? (
                    <img src={Wrongimg} width="16px"/>
                ) : (
                    <span></span>
                )}
              </td>
              <td>
                {record.answers === "submited" && record.aimgurl !== "wrong" ? (
                    <span>{record.aimgurl}</span>
                ) : record.answers === "submited" && record.aimgurl === "wrong" ? (
                    <span>0%</span>
                ) : (
                    <span></span>
                )}
              </td>
            </tr>
        ))}
        </tbody>
      </Table>
    </div>
  </Modal>
        </>
  )
}
