import React, {useEffect,useState} from "react";
import { API} from 'aws-amplify';
import {useParams} from "react-router-dom";
import {getDesmosfillin} from '../../../../graphql/queries';
import "../../../css/correctanswerpage.css";

function Mathfastercorrctanswer() {
    const [thisqq, setThisqq] = useState("");
    const [thisaa, setThisaa] = useState("");
    const [mathdata, setMathdata] = useState("");
    const [thisimage, setThisimage] = useState("");
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDatas();
    }, []);

    async function fetchDatas() {
        const response = await API.graphql({
            query: getDesmosfillin,
            variables:{id:rid}
        })
        const datas = response.data.getDesmosfillin;
       const qq = datas.fquestion;
       const aa = datas.fanswer;
        const ma = datas.mathstate;
        const mimage = datas.image;
        if(mimage !== null) {
            setThisimage(mimage)
        }
        if(ma !== null) {
            setMathdata(ma)
        }
        setThisqq(qq);
        setThisaa(aa);
    }
    return (
            <div className="correctanswerpage">
            <div className="corrctcenterthis">
                <h2>{thisqq}</h2>
                <img src={mathdata} className="fillinthimgshow" />
                <br/>
                <img src={thisimage} className="fillinthimgshow" />
                <br/>
                <br/>
                <p>The correct answer is: </p>
                <h3 className="redthis">{thisaa}</h3>
            </div>
            </div>
    );
}

export default Mathfastercorrctanswer;
