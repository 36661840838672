import React, {useState, useMemo, useEffect} from 'react'
import Item from './Item'
import {API} from "aws-amplify";
import {deleteAnswerslist, updateAnswerslist, updateLeaderboard} from "../../../graphql/mutations";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import Grid from "@material-ui/core/Grid";
import {Button, Table} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../css/taptap.css";
import moment from "moment";
import Correctimg from "../../../img/view/check-mark.png";
import Wrongimg from "../../../img/view/close.png";
import Modal from "react-modal";
import {newCreateAnswerslist, newDeleteAnswerslist, newUpdateAnswerslist} from "../../../graphql/subscriptions";
import {listAnswerslists} from "../../../graphql/queries";
import People from "../../../img/overview/people.png";
import Close from "../../../img/host/close.png";
import Submitteds from "../../../img/overview/submit.png";

const placeholderCategories = [
  {
    name: '',
    items: [
      {text: '', image: ''}
    ]
  },
  {
    name: '',
    items: [
      {text: '', image: ''},
      {text: '', image: ''}
    ]
  }
]

function shuffle(items) {
  const entries = items.map((item) => [Math.random(), item])
  entries.sort((a, b) => a[0] - b[0])
  return entries.map((entry) => entry[1])
}

export default function Showcategory() {
  const [quest, setQuest] = useState();
  const [timeval, setTimeval] = useState(0);
  const {id, rid} = useParams();
  const [messages, setMessages] = useState();
  const [thissaved, setThissaved] = useState([]);
  const [isShows, setIsShows] = useState(true);
  const [playpause, setPlaypause] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const [counter, setCounter] = useState();
  const [submittedmessage, setSubmittedmessage] = useState("");
  const [showclock, setShowclock] = useState(false);
  const [active, setActive] = useState(null);
  const [peoplenumbers, setPeoplenumbers] = useState(0);
  const [peoples, setPeoples] = useState([]);
  const [answerednumber, setAnswerednumbers] = useState(0);
  const [usertackingid, setUsertackingid] = useState("");
  const [usertotascore, setUsertotascore] = useState("");
  const [thisusername, setThisusername] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [thisiscorrect, setThisiscorrect] = useState(0);
  const [thisiswrong, setThisiswrong] = useState(0);
  const [thispeopleid, setThispeopleid] = useState("");
  const [isShowpeoples, setIsShowpeoples] = useState(false);
  const [mydata, setMydata] = useState([]);
  sessionStorage.setItem("edureachumthisrid", rid)

  function selectItem(text) {
    setActive(text)
  }

  useEffect(() => {
    const newCards = sessionStorage.getItem("dragdropcategoriesques");
    setQuest(newCards);
    const thisid = sessionStorage.getItem("edureachumthisid");
    setUsertackingid(thisid);
    const thisscore = sessionStorage.getItem("edureachumthistotalscore");
    setUsertotascore(thisscore);
    const newcounter = Number(sessionStorage.getItem("dragdropcategoriescounter"));
    const tuser = sessionStorage.getItem("edureachumthisuser");
    setThisusername(tuser);
    if (!tuser) {
      setIsShowpeoples(true)
    }
    const thispid = sessionStorage.getItem("thisflipshowroomreachumid");
    setThispeopleid(thispid)
    if (newcounter !== 0) {
      setCounter(newcounter);
      setShowclock(true);
    } else {
      setTimeval(40)
    }
    const submitrids = sessionStorage.getItem("submitrid");
    if (submitrids) {
      setThissaved(submitrids)
      if (submitrids.includes(rid)) {
        setDisabled(true);
        setSubmittedmessage("Submitted")
      }
    }
  }, []);

  const categories = useMemo(() => {
    const storedCategories = sessionStorage.getItem('dragdropcategories')
    if (storedCategories == null) {
      return placeholderCategories
    }
    const parsed = JSON.parse(storedCategories).map((category) => {
      const items = category.items.map((item) => {
        return {...item, category: category.name}
      })
      return {...category, items}
    })
    return parsed
  }, [])
  const [categoryItems, setCategoryItems] = useState(() => {
    return categories.reduce((acc, category) => {
      acc[category.name] = []
      return acc
    }, {})
  })

  const renderTime = ({remainingTime}) => {
    if (remainingTime === 0) {
      setTimeval(0)
      return <div className="timer">0</div>;
    } else {
      setTimeval(remainingTime)
    }
    return (
        <div className="timer">
          <div className="value">{remainingTime}</div>
        </div>
    );
  }
  const [answers, setAnswers] = useState({})
  const flatItems = categories.flatMap(category => category.items)
  const [generalItems, setGeneralItems] = useState(() => {
    const shuffledItems = shuffle(flatItems)
    return shuffledItems
  })

  function handleGeneralClick() {
    if (active == null || generalItems.some(item => item.text === active.text)) return
    setGeneralItems(currentGeneralItems => [...currentGeneralItems, active])
    setCategoryItems(currentCategoryItems => {
      const newCategoryItems = {...currentCategoryItems}
      categories.forEach(category => {
        newCategoryItems[category.name] = newCategoryItems[category.name].filter(
            item => item.text !== active.text
        )
      })
      return newCategoryItems
    })
    setActive(null)
  }

  const generalItemsViews = generalItems.map((item, index) => {
    return (
        <Item
            key={index}
            item={item}
            categories={categories}
            setCategoryItems={setCategoryItems}
            setAllItems={setGeneralItems}
            active={active}
            setActive={setActive}
        />
    )
  })
  const generalView = (
      <div
          style={{
            display: 'flex',
            flexWrap: "wrap",
            background: '#ececec',
            alignItems: "center",
            justifyContent: "center",
            margin: '10px',
            minHeight: '80px',
            borderRadius: "5px"
          }}
          onClick={handleGeneralClick}
      >
        {generalItemsViews}
      </div>
  )
  const [missing, setMissing] = useState(() => {
    return categories.reduce((acc, category) => {
      acc[category.name] = []
      return acc
    }, {})
  })
  const categoryViews = categories.map((category, index) => {
    const categoryAnswers = answers[category.name]
    const categoryItemViews = categoryItems[category.name].map((item, index) => {
      const answer = categoryAnswers && categoryAnswers[index]
      return (
          <Item
              key={index}
              answer={answer}
              item={item}
              categories={categories}
              setCategoryItems={setCategoryItems}
              setAllItems={setGeneralItems}
              active={active}
              setActive={setActive}
          />
      )
    })
    const missingItems = missing[category.name]
    const missingItemViews = missingItems.map(missingItem => {
      return <div style={{padding: '10px', margin: '20px', color: "#daf4a2"}}>{missingItem.text} <br/> <img
          src={missingItem.image} width="220px"/></div>
    })

    function handleClick() {
      if (active == null) return
      if (categoryItems[category.name].some(item => item.text === active.text)) return
      setGeneralItems(
          currentGeneralItems => currentGeneralItems.filter(item => item.text !== active.text)
      )
      setCategoryItems(currentCategoryItems => {
        const newCategoryItems = {...currentCategoryItems}
        categories.forEach(otherCategory => {
          const otherItems = newCategoryItems[otherCategory.name]
          const categoryMatch = otherCategory.name === category.name
          if (categoryMatch) {
            const mergedItems = [...otherItems, active]
            newCategoryItems[otherCategory.name] = mergedItems
          } else {
            newCategoryItems[otherCategory.name] = otherItems.filter(
                otherItem => otherItem.text !== active.text
            )
          }
        })
        return newCategoryItems
      })
      setActive(null)
    }

    return (
        <div
            key={index}
            className="catelists"
            onClick={handleClick}
        >
          <h5>{category.name}</h5>
          <hr/>
          <div style={{
            display: 'flex',
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#034f4b"
          }}>
            {categoryItemViews}
            {missingItemViews}
          </div>
        </div>
    )
  })
  useEffect(() => {
    fetchData();
    const createanswerslist = API.graphql(
        {
          query: newCreateAnswerslist
        }
    ).subscribe({
      next: () => {
        fetchData();
      }
    })
    const updateanswerslist = API.graphql(
        {
          query: newUpdateAnswerslist
        }
    ).subscribe({
      next: () => {
        fetchData();
      }
    })
    const deleteanswerslist = API.graphql(
        {
          query: newDeleteAnswerslist
        }
    ).subscribe({
      next: () => {
        fetchData();
      }
    })
    return () => {
      createanswerslist.unsubscribe();
      updateanswerslist.unsubscribe();
      deleteanswerslist.unsubscribe()
    }
  }, []);

  function handleSubmit() {
    const correctItems = flatItems.filter((item) => {
      const selectedCategory = categories.find((category) => {
        const items = categoryItems[category.name]
        const selected = items.some((otherItem) => otherItem.text === item.text)
        return selected
      })
      if (selectedCategory == null) {
        return false
      }
      return selectedCategory.name === item.category
    })
    const scres = correctItems.length;
    const totalscore = flatItems.length;
    let tt = [];
    if (thissaved) {
      tt = [thissaved, rid]
    } else {
      tt = [rid]
    }
    sessionStorage.setItem("submitrid", tt);
    setPlaypause(false);
    const vv = Math.round((scres / totalscore) * 100) + "%";
    const ttr = timeval * scres;
    const cm = ttr / totalscore;
    const scre = Math.floor(cm);
    if (scre > 0) {
      const thisques = sessionStorage.getItem("edureachumthisquestion");
      const start = sessionStorage.getItem("edureachumthisstarttimer");
      const thisqueslist = sessionStorage.getItem("edureachumthisques");
      const tts = scre + Number(usertotascore);
      const end = new Date();
      const timeDiff = end - new Date(start);
      const seconds = parseInt((timeDiff / 1000) % 60);
      try {
        const thisqq = {
          rid: rid,
          question: thisques,
          correctanswer: "correct",
          thisscore: scre,
          duration: seconds
        }
        let qq;
        if (thisqueslist) {
          const objectArray = JSON.parse(thisqueslist);
          qq = [...objectArray, thisqq];
        } else {
          qq = [thisqq]
        }
        const input = {
          id: usertackingid,
          name: thisusername,
          questions: qq,
          thisgamescore: scre,
          score: tts
        }
        API.graphql({
          query: updateLeaderboard,
          variables: {input}
        });
        try {
          const input2 = {
            id: thispeopleid,
            aimgurl: vv,
            answers: "submited"
          }
          API.graphql({
            query: updateAnswerslist,
            variables: {input: input2}
          })
        } catch (err) {
          console.log(err)
        }
        setMessages(`You scored ${scre} points.`);
      } catch (err) {
        setSubmittedmessage("For the best experience, please use a public browser and a stable internet connection.")
      }
    } else {
      const thisques = sessionStorage.getItem("edureachumthisquestion");
      const start = sessionStorage.getItem("edureachumthisstarttimer");
      const thisqueslist = sessionStorage.getItem("edureachumthisques");
      const end = new Date();
      const timeDiff = end - new Date(start);
      const seconds = parseInt((timeDiff / 1000) % 60);
      try {
        const thisqq = {
          rid: rid,
          question: thisques,
          correctanswer: "wrong",
          thisscore: 0,
          duration: seconds
        }
        let qq;
        if (thisqueslist) {
          const objectArray = JSON.parse(thisqueslist);
          qq = [...objectArray, thisqq];
        } else {
          qq = [thisqq]
        }
        const input = {
          id: usertackingid,
          name: thisusername,
          questions: qq,
          thisgamescore: 0
        }
        API.graphql({
          query: updateLeaderboard,
          variables: {input}
        });
      } catch (err) {
        console.log(err)
      }
      setMessages("You scored 0 points.");
      fetchupdatecorrectData()
    }
    const itemEntries = Object.entries(categoryItems)
    const answerEntries = itemEntries.map(entry => {
      const [name, items] = entry
      const placeholderCategory = categories.find(category => category.name === name)
      const itemAnswers = items.map((item, index) => {
        const correct = placeholderCategory.items.some(placeholderItem => {
          return placeholderItem.text === item.text
        })
        return correct
      })
      return [name, itemAnswers]
    })
    const newAnswers = Object.fromEntries(answerEntries)
    setAnswers(newAnswers)
    const missingEntries = itemEntries.map(entry => {
      const [name, items] = entry
      const placeholderCategory = categories.find(category => category.name === name)
      const missing = placeholderCategory.items.filter(placeholderItem => {
        const something = items.some(item => item.text === placeholderItem.text)
        return !something
      })
      return [name, missing]
    })
    const newMissing = Object.fromEntries(missingEntries)
    setMissing(newMissing);
    setIsShows(false);
  }

  const fetchupdatecorrectData = async () => {
    const input1 = {
      id: thispeopleid,
      aimgurl: "wrong",
      answers: "submited"
    }
    await API.graphql({
      query: updateAnswerslist,
      variables: {input: input1}
    })
  }
  const getdatas = async () => {
    const mymeetemals = localStorage.getItem("vreachumneweremail");
    if (mymeetemals) {
      const thisques = sessionStorage.getItem("edureachumthisquestion");
      const thisqueslist = sessionStorage.getItem("edureachumthisques");
      try {
        const thisqq = {
          rid: rid,
          question: thisques,
          correctanswer: "No answer",
          thisscore: 0
        }
        let qq;
        if (thisqueslist) {
          const objectArray = JSON.parse(thisqueslist);
          qq = [...objectArray, thisqq];
        } else {
          qq = [thisqq]
        }
        const input = {
          id: usertackingid,
          name: thisusername,
          questions: qq,
          thisgamescore: 0
        }
        API.graphql({
          query: updateLeaderboard,
          variables: {input}
        });
      } catch (err) {
        console.log(err)
      }
      setMessages("You scored 0 points.")
      setIsShows(false);
    } else {
      setMessages("Time is up.")
      setIsShows(false);
    }
  }
  const fetchData = async () => {
    const response = await API.graphql({
      query: listAnswerslists,
      variables: {
        filter: {
          "answerid": {
            eq: `2${rid}`
          }
        },
        limit: 10000
      }
    })

    let filteredData = response.data.listAnswerslists.items;
    const nameid = filteredData.map(item => item.id);
    setMydata(nameid)
    const merged = mergeRecordsByEmail(filteredData);
    setPeoples(merged);
    setPeoplenumbers(merged.length);
    const countAnswered = merged.filter(record => record.answers === "submited").length;
    setAnswerednumbers(countAnswered);
    const countcorrctAnswered = merged.filter(record => record.answers === "submited");
    const iswrongnumber = countcorrctAnswered.filter(record => record.aimgurl === "wrong").length;
    const iscorrectnumber = Number(countAnswered - iswrongnumber);
    setThisiscorrect(iscorrectnumber);
    setThisiswrong(iswrongnumber);
  }
  const mergeRecordsByEmail = (records) => {
    const mergedRecords = {};
    records.forEach(record => {
      const {email, answerid, answers, aimgurl} = record;
      if (!mergedRecords[email]) {
        mergedRecords[email] = {
          email,
          name: record.name,
          answerid: null,
          answers: null,
          aimgurl: null
        };
      }
      if (answers === "submited") {
        mergedRecords[email].answerid = answerid;
        mergedRecords[email].answers = answers;
        mergedRecords[email].aimgurl = aimgurl;
      } else if (mergedRecords[email].answerid === null) {
        mergedRecords[email].answerid = answerid;
      }
    });

    // Return merged records as an array
    return Object.values(mergedRecords);
  };

  function removealldatas() {
    let text = "Are you sure you want to delete this data? \nEither OK or Cancel.";
    if (window.confirm(text) === true) {
      const deleteMultipleEntries = async () => {
        try {
          for (let id of mydata) {
            const input = {id};
            await API.graphql({
              query: deleteAnswerslist,  // Your GraphQL mutation to delete an item
              variables: {input}
            });
          }
        } catch (error) {
          console.error("Error deleting entries:", error);
        }
      };
      deleteMultipleEntries(mydata);
    }

  }

  useEffect(() => {
    const onClose = () => {
      const thisid = sessionStorage.getItem("edureachumthisid");
      const ends = moment().format('dddd  MMMM Do YYYY  h:mm:ss a');
      try {
        const input = {
          id: thisid,
          timerout: ends
        }
        API.graphql({
          query: updateLeaderboard,
          variables: {input}
        });
      } catch (err) {
        console.log(err)
      }
    }
    window.addEventListener('beforeunload', onClose)
    window.addEventListener('blur', onClose);
    window.addEventListener('pagehide', onClose);
    return () => {
      window.removeEventListener('beforeunload', onClose);
      window.removeEventListener('blur', onClose);
      window.removeEventListener('pagehide', onClose);
    }
  }, []);
  return (
      <>
        <div className="drawoptionpage2">
          <div className="greenback">
            <Grid container spacing={0}>
              <Grid item xs={2}>
                {showclock ?
                    <div className="lpollheadclock2">
                      <CountdownCircleTimer
                          isPlaying={playpause}
                          duration={counter}
                          size={50}
                          colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                          colorsTime={[10, 6, 3, 0]}
                          onComplete={getdatas}
                      >
                        {renderTime}
                      </CountdownCircleTimer>
                    </div>
                    : ""
                }
              </Grid>
              <Grid item xs={10}>
                <div className="fastanswes">
                  <h2>{quest}</h2>
                </div>
              </Grid>
            </Grid>
          </div>
          <div>
            {isShowpeoples ?
                <div className="peoplesview">
                  <img src={People} onClick={() => setShowPopup(true)}/>
                </div>
                : ""
            }
          </div>
          <div>
            <>
              {isShows ?
                  <>
                    {generalView}
                  </>
                  :
                  ""
              }
            </>
            <br/>
            <div style={{
              display: 'flex',
              flexWrap: "wrap",
              width: "98%",
              margin: "5px auto",
              alignItems: "center",
              justifyContent: "center"
            }}>
              {categoryViews}
            </div>
          </div>
          <br/>
          <br/>
          {isShows ?
              <Grid container spacing={0} className="marginbottom">
                <Grid item xs={6} className="rightside">
                </Grid>
                <Grid item xs={5} className="rightsidethis">
                  <p className="redthis"> {submittedmessage} </p>
                  <Button onClick={handleSubmit} disabled={isDisabled} className="redbutton">Submit</Button>
                </Grid>
                <Grid item xs={1}>
                </Grid>
              </Grid>
              :
              <>
                <div className="cccenter">
                  <br/>
                  <p className="redthis">{messages}</p>
                  <br/>
                  <br/>
                  <br/>
                </div>
              </>
          }

        </div>
        <Modal isOpen={showPopup} ariaHideApp={false}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="leftside">
                <Button onClick={removealldatas} variant="outline-secondary" size="sm"> Clear </Button>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="rightside">
                <img src={Close} onClick={() => setShowPopup(false)} alt="Close"/>
              </div>
            </Grid>
          </Grid>
          <div className="cccenter">
            <h3><img src={People}/> <span className="redthis">{peoplenumbers}</span> &nbsp; <img src={Submitteds}/>
              <span className="redthis">{answerednumber}</span> &nbsp; <img
                  src={Correctimg} width="25px"/> <span className="redthis">{thisiscorrect}</span> &nbsp; <img
                  src={Wrongimg} width="18px"/> <span className="redthis">{thisiswrong}</span>
            </h3>
            <br/>
          </div>
          <div>
            <Table hover size="sm" className="sizeforbtable">
              <thead>
              <tr>
                <th> Name</th>
                <th className="cccenter"> Correct / Incorrect</th>
                <th className="cccenter"> % Correct</th>
              </tr>
              </thead>
              <tbody>
              {peoples.map((record, index) => (
                  <tr
                      key={index}
                      style={{
                        color: record.answers === null ? '#000000' : '#000000', // Apply red color if answers is null
                        marginBottom: '10px',
                      }} className="peopleviewsconitem">
                    <td className="leftsitethis">{record.name}</td>
                    <td>
                      {record.answers === "submited" && record.aimgurl !== "wrong" ? (
                          <img src={Correctimg} width="20px"/>
                      ) : record.answers === "submited" && record.aimgurl === "wrong" ? (
                          <img src={Wrongimg} width="16px"/>
                      ) : (
                          <span></span>
                      )}
                    </td>
                    <td>
                      {record.answers === "submited" && record.aimgurl !== "wrong" ? (
                          <span>{record.aimgurl}</span>
                      ) : record.answers === "submited" && record.aimgurl === "wrong" ? (
                          <span>0%</span>
                      ) : (
                          <span></span>
                      )}
                    </td>
                  </tr>
              ))}
              </tbody>
            </Table>
          </div>
        </Modal>
      </>
  )
}
