import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getPlayslide, listLeaderboards, listEngagements} from '../../../graphql/queries';
import "../../css/leaderboard.css";
import {deleteLeaderboard, deleteEngagement} from "../../../graphql/mutations";
import writeXlsx from 'write-excel-file';

function Cdlessonlog(props) {
    const  id  = props.data;
    const [myData, setMyData] = useState([]);
    const [roomname, setRoomname] = useState("");
    const [thisdatas, setThisdatas] = useState([]);
    useEffect(() => {
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const myroomname = datas.meetingname;
            setRoomname(myroomname)
        })()
        fetchDas();
    }, []);

    async function fetchDas() {
        const scounts = [];
        const dcounts=[];
        try {
            const response = await API.graphql({
                query: listLeaderboards,
                variables: {
                    filter: {
                        "answerid": {
                            eq: id
                        }
                    },
                    limit:10000
                }
            })
            const datas = response.data.listLeaderboards.items;
            setThisdatas(datas);
            datas.forEach((data) => {
                const listdata = data;
                scounts.push(listdata)
            })
            scounts.sort((a, b) => {
                return b.score - a.score;
            });
            scounts.forEach((e) => {
                dcounts.push(e)
            });
            const result =  dcounts.map(item => {
                const totalDuration = item.questions
                    ? item.questions.reduce((acc, question) => acc + question.duration, 0)
                    : 0;
                return {
                    ...item,
                    dura: totalDuration,
                };
            });
            setMyData(result);
        }catch(err){
            console.log(err)
        }
    }
    const rows = [];

    // Add header
    rows.push([
        { value: 'Name', fontWeight: 'bold' },
        { value: 'Email', fontWeight: 'bold' },
        { value: 'Score', fontWeight: 'bold' },
        { value: 'Timer In', fontWeight: 'bold' },
        { value: 'Timer Out', fontWeight: 'bold' },
        { value: 'Activity', fontWeight: 'bold' },
        { value: 'Correct Answer', fontWeight: 'bold' },
        { value: 'Score for Question', fontWeight: 'bold' },
        { value: 'Duration', fontWeight: 'bold' },
    ]);

    // Flatten and format the data
    myData.forEach((participant) => {
        if (participant.questions && Array.isArray(participant.questions)) {
            participant.questions.forEach((question) => {
                rows.push([
                    { value: participant.name },
                    { value: participant.meetemail },
                    { value: participant.score },
                    { value: participant.timerin },
                    { value: participant.timerout },
                    { value: question.question },
                    { value: question.correctanswer !== null ? String(question.correctanswer) : '' },
                    { value: question.thisscore !== null ? question.thisscore : '' },
                    { value: question.duration },
                ]);
            });
        } else {
            // Handle cases where questions is null or undefined
            rows.push([
                { value: participant.name },
                { value: participant.meetemail },
                { value: participant.score },
                { value: participant.timerin },
                { value: participant.timerout },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
            ]);
        }
    });
    const downloadlessonFile = async () => {
        await writeXlsx(rows, {fileName: `${roomname} - Reachum.xlsx`})
    };

    const clearresults = async ()=>{
        let text = "Are you sure you want to delete all players? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            try {
                let cl = thisdatas.length;
                for (let i = 0; i < cl; i++) {
                    const input = {
                        id: thisdatas[i].id
                    };
                    await API.graphql({
                        query: deleteLeaderboard,
                        variables: {input}
                    });
                }
            } catch (err) {
                console.log(err)
            }
            try {
                const response = await API.graphql({
                    query: listEngagements,
                    variables: {
                        filter: {
                            "questionid": {
                                eq: id
                            }
                        },
                        limit:10000
                    }
                })
                const datas = response.data.listEngagements.items;
                const datalength = datas.length;
                for(var j=0; j<datalength; j++) {
                    const input = {
                        id: datas[j].id
                    };
                    await API.graphql({
                        query: deleteEngagement,
                        variables: {input}
                    });
                }
            }catch(err){
                console.log(err)
            }
            window.location.reload();
        }
    }
    return (
        <>
                        <div className="leaderpage">
                                <div>
                                    <button onClick={downloadlessonFile} className="savespread">Download</button>
                                    <br/>
                                    <br/>
                                    <button onClick={clearresults} className="clearresub">Clear Results</button>
                                </div>
                        </div>
                    </>
    )
}

export default Cdlessonlog;
