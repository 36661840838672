import React, {Suspense, useEffect, useState} from "react";
import { API } from 'aws-amplify';
import {getPlayslide} from '../../../graphql/queries';
import {useParams} from "react-router-dom";
import {Image} from "react-bootstrap";
import "../../css/imgshowroom.css";

function Gifroom() {
    const [jvfile, setVjfile] = useState();
    const { id, rid } = useParams();
    const [itt, setItt] = useState("");
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        fetchDatas();
    }, []);

    async function fetchDatas() {
        const response = await API.graphql({
            query: getPlayslide,
            variables: {id}
        });
        const datas = response.data.getPlayslide.playlistlinks;
        const newdatas = datas.filter(data => data.rid === rid);
        const vbucket = newdatas[0].file.bucket;
        const vkey = newdatas[0].file.key;
        const rurls = `https://${vbucket}.s3.amazonaws.com/public/${vkey}`;
        setVjfile(rurls);
        const tt = newdatas[0].title;
        setItt(tt)
    }

    return (
        <>
            <div className="imgshows">
                <Suspense fallback={<div className="lazyloading">Loading ...</div>}>
                <Image src={jvfile} fluid className="imgviewsite" alt={itt}/>
                </Suspense>
            </div>
            </>
    );
}

export default Gifroom;
