import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {listPolls, getPolllist} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import ReactCountdownClock from "react-countdown-clock";
import {Button} from "react-bootstrap";
import "../css/poll.css";
import { createPolls} from "../../graphql/mutations";
import Grid from "@material-ui/core/Grid";
import {v4 as uuid} from "uuid";


function Pollshowroom() {
    const [isOpen, setIsOpen] = useState(true);
    const [isShow, setIsShow] = useState(false);
    const [quest, setQuest] = useState();
    const [pchecked, setPchecked] = useState();
    const [messages, setMessages] = useState();
    const [answerlength, setAnswerlength] = useState();
    const [pcheckedpeople, setPcheckedpeople] = useState();
    const [clist, setClist] = useState([]);
    const [texts, setTexts] = useState();
    const {id, rid} = useParams();
    const [counter, setCounter] = useState();
    const ppid = uuid();
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        setTimeout(() => {
            setIsShow(true);
        }, 500)
    }, []);
    useEffect(() => {
        fetchDas();
    }, []);

    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getPolllist,
                variables: {id: rid}
            })
            const datas = response.data.getPolllist;
            const timers = datas.ptimer;
            setCounter(timers)
            const ques = datas.pquestion;
            setQuest(ques);
            const tests = datas.ptest;
            setClist(tests)
            const pt = datas.ptest;
            const pl = pt.length;
            setAnswerlength(pl)
        } catch (err) {
            console.log(err)
        }
    }

    const psubmit = async (e) => {
        try {
            const input = {
                id: ppid,
                panswer: pchecked,
                PolllistId: rid
            }
            await API.graphql({
                query: createPolls,
                variables: {input}
            });
            if (counter > 0) {
                setMessages("Group results shown on time out.")
            }
            document.getElementById("pollsubmit").setAttribute("disabled", "true");
            document.getElementById("pollsubmit").style.backgroundColor = "#E24761";
        } catch (err) {
            console.log(err)
        }
    };
    const getdatas = async () => {
        try {
            const response = await API.graphql({
                query: listPolls,
                variables: {
                    filter: {
                        "PolllistId": {
                            eq: rid
                        }
                    },
                    limit: 10000
                }
            })
            const results = response.data.listPolls.items;
            const clength = results.length;
            setPcheckedpeople(clength);
            let text = [];
            let array = [];
            let iarray;
            for (let j = 0; j < clength; j++) {
                const parray = results[j].panswer;
                array.push(parray)
            }
            for (let i = 0; i < answerlength; i++) {
                iarray = array.filter(item => item === i);
                const pcents = (((iarray.length) / clength) * 100).toFixed(0) + "%";
                text.push(pcents)
            }
            const listItems = text.map((val, index) =>
                <li className="polllist1" key={index}><p className="percentmiddle">{val}</p></li>
            )
            setTexts(listItems)
            setMessages("");
            document.getElementById("pollsubmit").setAttribute("disabled", "true");
            document.getElementById("pollsubmit").style.backgroundColor = "#E24761";
            setTimeout(() => {
                setIsOpen(false);
                document.getElementById("pollsubmit").style.display = "none";
            }, 600)
            setTimeout(function () {
                getdatas()
            }, 5000);
        } catch (err) {
            console.log(err)
        }
    }
    return (
        <>
            <div className="pollshowroom2">
                <div className="purpleback">
                    <div className="pollheader2">
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <div className="lpollheadclock3">
                                    <ReactCountdownClock seconds={counter}
                                                         color="#bca0dc"
                                                         alpha={1}
                                                         size={50}
                                                         weight={10}
                                                         onComplete={getdatas}/>
                                </div>
                            </Grid>
                            <Grid item xs={10}>
                                <div className="fastanswes">
                                    <h2>{quest}</h2>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <br/>
            </div>
            <div className="pollview1">
                <>
                    {!isOpen ?
                        <>
                            <p>{pcheckedpeople} Responses</p>
                            <p className="pollnoanswers">Thank you for participating!</p>
                            <ol className="pollcontainer1">
                                {
                                    texts

                                }
                            </ol>
                        </>
                        : ""
                    }
                </>
                <div>
                    <ol className="pollcontainer">
                        {
                            clist.map((item, index) => {
                                    return (
                                        <li key={item.id} className="polllist">
                                            <input
                                                type="radio"
                                                defaultValue={item.panswer}
                                                name="panswer"
                                                onChange={() => {
                                                    setPchecked(item.id)
                                                }}
                                                id={item.id}
                                            />
                                            <label htmlFor={item.id}>{item.panswer}</label>
                                            <div className="check"></div>
                                        </li>
                                    )
                                }
                            )
                        }

                    </ol>
                    <br/>
                    {isShow ?
                        <Grid container spacing={0} className="pollbottoms">
                            <Grid item xs={7}>
                                <p className="pollmaeeage">{messages}</p>
                            </Grid>
                            <Grid item xs={5} className="rightside">
                                <Button id="pollsubmit" onClick={psubmit}
                                        className="purplebutton">Submit</Button>
                            </Grid>
                        </Grid> : ""
                    }
                </div>
            </div>
        </>
    )
}

export default Pollshowroom;
