import React, {useEffect,useState} from "react";
import { API} from 'aws-amplify';
import {useParams} from "react-router-dom";
import {getDesmosfillin} from '../../../graphql/queries';
import "../../css/correctanswerpage.css";


function Fastercorrctimganswer() {
    const [thisqq, setThisqq] = useState("");
    const [thisaa, setThisaa] = useState("");
    const [thisimg, setThisimg] =useState("");
    const [isopenimg, setIsopenimg] = useState(false);
    const { id, rid } = useParams();
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        fetchDatas();
    }, []);

    async function fetchDatas() {
        const response = await API.graphql({
            query: getDesmosfillin,
            variables:{id:rid}
        })
        const datas = response.data.getDesmosfillin;
       const qq = datas.fquestion;
        sessionStorage.setItem("edureachumthisquestion", qq)
        const thisimg = datas.mathstate;
        if(thisimg){
            setThisimg(thisimg);
            setIsopenimg(true)
        }
       const aa = datas.fanswer;
       setThisqq(qq);
       setThisaa(aa)
    }

    return (
            <div className="correctanswerpage">
            <div className="corrctcenterthis">
                <h2>{thisqq}</h2>
                <div className="centerside">
                    <br/>
                    {isopenimg ?
                        <img src={thisimg} className="fillinthimgshow"/>
                        : ""
                    }
                </div>
                <br/>
                <p>The correct answer is: </p>
                <h3 className="redthis">{thisaa}</h3>
            </div>
            </div>
    );
}

export default Fastercorrctimganswer;
