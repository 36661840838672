import React, {useState, useEffect} from 'react';
import {API} from 'aws-amplify';
import {useParams} from "react-router-dom";
import {getFasteranswer} from "../../graphql/queries";
import "./style.css"

const Mediaflipcardshow = () => {
    const {id, rid} = useParams();
    const [thisimage, setThisimage] = useState('')
    const [thisgimage, setThisgimage] = useState('')
    const [thismathimage, setThismathimage] = useState('')
    const [isFlipped, setIsFlipped] = useState(false);
    const [isimgsopen, setIsimgsopen] = useState(false);
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        const response = await API.graphql({
            query: getFasteranswer,
            variables: {id: rid}
        })
        const datas = response.data.getFasteranswer;
        const tt = datas.fanswer;
        sessionStorage.setItem("edureachumthisquestion", tt)
        if (tt !== null) {
            setThisgimage(tt)
        }
        const mt = datas.fimage;
        if (mt !== "") {
            setThismathimage(mt);
            setIsimgsopen(true)
        }
        const mimage = datas.fquestion;
        if (mimage !== null) {
            setThisimage(mimage)
        }
    }

    const handleCardClick = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <>
            <div className="mediaflipcard">
                <div className={`mediaflip-card ${isFlipped ? 'flipped' : ''}`}>
                    <div className="mediaflip-card-inner">
                        <div className="mediaflip-card-front" onClick={handleCardClick}>
                            <h1>{thisgimage}</h1>
                        </div>
                        <div className="mediaflip-card-back" onClick={handleCardClick}>
                            <div className="mediainnerflipcard">
                                {isimgsopen ?
                                    <>
                                        <div className="flashcardleft">
                                            <h2>{thisimage}</h2>
                                        </div>
                                        <div className="flashcardright">
                                            <img src={thismathimage}/>
                                        </div>
                                    </>
                                    :
                                    <div className="centerthisflashcards">
                                        <h2>{thisimage}</h2>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
            </div>
        </>
    );
}

export default Mediaflipcardshow;
