import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getPlayslide, listLeaderboards} from '../../../graphql/queries';
import "../../css/leaderboard.css";
import writeXlsx from 'write-excel-file';

function Downloadlessonlog(props) {
    const  id  = props.data;
    const [myData, setMyData] = useState([]);
    const [roomname, setRoomname] = useState("");
    useEffect(() => {
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const myroomname = datas.meetingname;
            setRoomname(myroomname)
        })()
        fetchDas();
    }, []);

    async function fetchDas() {
        const scounts = [];
        const dcounts=[];
        try {
            const response = await API.graphql({
                query: listLeaderboards,
                variables: {
                    filter: {
                        "answerid": {
                            eq: id
                        }
                    },
                    limit:10000
                }
            })
            const datas = response.data.listLeaderboards.items;
            datas.forEach((data) => {
                const listdata = data;
                scounts.push(listdata)
            })
            scounts.sort((a, b) => {
                return b.score - a.score;
            });
            scounts.forEach((e) => {
                dcounts.push(e)
            });
            setMyData(dcounts)
        }catch(err){
            console.log(err)
        }
    }
    const rows = [];

    // Add header
    rows.push([
        { value: 'Name', fontWeight: 'bold' },
        { value: 'Email', fontWeight: 'bold' },
        { value: 'Score', fontWeight: 'bold' },
        { value: 'Timer In', fontWeight: 'bold' },
        { value: 'Timer Out', fontWeight: 'bold' },
        { value: 'Activity', fontWeight: 'bold' },
        { value: 'Correct Answer', fontWeight: 'bold' },
        { value: 'Score for Question', fontWeight: 'bold' },
        { value: 'Duration', fontWeight: 'bold' }
    ]);

    // Flatten and format the data
    myData.forEach((participant) => {
        if (participant.questions && Array.isArray(participant.questions)) {
            participant.questions.forEach((question) => {
                rows.push([
                    { value: participant.name },
                    { value: participant.meetemail },
                    { value: participant.score },
                    { value: participant.timerin },
                    { value: participant.timerout },
                    { value: question.question },
                    { value: question.correctanswer !== null ? String(question.correctanswer) : '' },
                    { value: question.thisscore !== null ? question.thisscore : '' },
                    { value: question.duration },
                ]);
            });
        } else {
            // Handle cases where questions is null or undefined
            rows.push([
                { value: participant.name },
                { value: participant.meetemail },
                { value: participant.score },
                { value: participant.timerin },
                { value: participant.timerout },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
            ]);
        }
    });
    const downloadlessonFile = async () => {
        await writeXlsx(rows, {fileName: `${roomname} - Reachum.xlsx`})
    };

    return (
        <>
                        <div className="leaderpage">
                                <div>
                                    <button onClick={downloadlessonFile} className="savespread">Download</button>
                                </div>
                        </div>
                    </>
    )
}

export default Downloadlessonlog;
