import React, {useEffect, useState} from "react";
import {API, Auth, Storage} from 'aws-amplify';
import {
    getAnswers,
    getPlayslide,
    listAnswerslists,
    getHightlightanswers,
    listLeaderboards
} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import {Button, Modal, ListGroup} from "react-bootstrap";
import "../css/answers.css";
import {
    createAnswerslist,
    deleteAnswerslist,
    createHightlightanswers,
    updateHightlightanswers,
    deleteHightlightanswers
} from "../../graphql/mutations";
import Grid from "@material-ui/core/Grid";
import {v4 as uuid} from 'uuid';
import {
    newDeleteAnswerslist,
    newCreateAnswerslist,
    newUpdateHightlightanswers,
    newDeleteHightlightanswers
} from "../../graphql/subscriptions";
import awsExports from "../../aws-exports";
import {updateLeaderboard, createLeaderboard} from "../../graphql/mutations";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Resizer from "react-image-file-resizer";
import Deletebutton from "./Deletebutton.svg";
import moment from "moment";

var XLSX = require("xlsx");

let vbucket = awsExports.aws_user_files_s3_bucket;
let vregion = awsExports.aws_user_files_s3_bucket_region;

function Answerroom() {
    const [isallopen, setIsallopen] = useState(false)
    const [isOpen, setIsOpen] = useState(true);
    const [isOpens, setIsOpens] = useState(false);
    const [myData, setMyData] = useState([]);
    const [isnameopen, setIsnameopen] = useState(false);
    const [isemailopen, setIsemailopen] = useState(false);
    const [selectedFile, setSelectedFile] = useState("");
    const [processing, setProcessing] = useState("");
    const [error, setError] = useState('');
    const [isimage, setIsimage] = useState(null)
    const [quest, setQuest] = useState();
    const [thisimg, setThisimg] = useState()
    const [thistext, setThistext] = useState();
    const [thisvimg, setThisvimg] = useState()
    const [thisvtext, setThisvtext] = useState();
    const [vals, setVals] = useState("");
    const [submitmessage, setSubmitmessage] = useState("");
    const {id, rid} = useParams();
    const [isDisabled, setDisabled] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [waitingresponse, setWaitingresponse] = useState("Waiting for responses ...");
    const [containerHeight, setContainerHeight] = useState('calc(100vh - 150px)');
    const [preview, setPreview] = useState(null);
    const d = uuid();
    sessionStorage.setItem("edureachumthisrid", rid)
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => (sessionStorage.setItem("answerowner", user.attributes.email)))
            .catch(err => console.log(err));
        fetchowner()
        fetchDas();
        fetchhightData();
        setTimeout(function () {
            setIsallopen(true)
        }, 500);
    }, []);
    useEffect(() => {
        fetchData();
        const deleteAnswerslist = API.graphql(
            {
                query: newDeleteAnswerslist
            }
        ).subscribe({
            next: () => {
                fetchData();

            }
        })
        const createanswerslist = API.graphql(
            {
                query: newCreateAnswerslist
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        const updatehightlightanswers = API.graphql(
            {
                query: newUpdateHightlightanswers
            }
        ).subscribe({
            next: () => {
                gethightData();
            }
        })
        const deletehightlightanswers = API.graphql(
            {
                query: newDeleteHightlightanswers
            }
        ).subscribe({
            next: () => {
                gethightDatas()
            }
        })
        return () => {
            deleteAnswerslist.unsubscribe();
            createanswerslist.unsubscribe();
            updatehightlightanswers.unsubscribe();
            deletehightlightanswers.unsubscribe()
        }
    }, []);

    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getAnswers,
                variables: {id: rid}
            })
            const datas = response.data.getAnswers;
            const ques = datas.fquestion;
            setQuest(ques);
            sessionStorage.setItem("edureachumthisquestion", ques)
        } catch (err) {
            console.log(err)
        }
    }

    async function fetchowner() {
        try {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            const t = sessionStorage.getItem("answerowner");
            if (t === iowner) {
                setIsOpen(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function fetchData() {
        const scounts = [];
        const dcounts = [];
        try {
            const response = await API.graphql({
                query: listAnswerslists,
                variables: {
                    filter: {
                        "answerid": {
                            eq: rid
                        }
                    }
                }
            })
            const datas = response.data.listAnswerslists;
            const yy = datas.items;
            if (yy.length !== 0) {
                yy.forEach((data) => {
                    const listdata = data;
                    scounts.push(listdata)
                })
                const sortedData = [...scounts].sort((a, b) => {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                });
                sortedData.forEach((e) => {
                    dcounts.push(e)
                });
                setMyData(dcounts);
                setContainerHeight('0px');
                setWaitingresponse("")
            } else {
                setMyData([]);
                setContainerHeight('calc(100vh - 150px)');
                setWaitingresponse("Waiting for responses ...")
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function fetchhightData() {
        const input = {
            id: rid
        }
        await API.graphql({
            query: createHightlightanswers,
            variables: {input}
        });
    }

    async function gethightData() {
        try {
            const response = await API.graphql({
                query: getHightlightanswers,
                variables: {id: rid}
            })
            const datas = response.data.getHightlightanswers;
            const thisa = datas.thisanswer;
            const thisuims = datas.thgisaimgurl;
            if (thisuims === null) {
                setIsimage(false)
            } else {
                setThisvimg(thisuims);
                setIsimage(true)
            }
            setThisvtext(thisa);
            setIsOpens(true)
        } catch (err) {
            console.log(err)
        }
    }

    function gethightDatas() {
        setIsOpens(false)
    }

    const handleFileChange = (event) => {
        var file = false;
        if (event.target.files[0]) {
            file = true;
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            setPreview(null);
        }
        if (file) {
            try {
                Resizer.imageFileResizer(
                    event.target.files[0],
                    400,
                    400,
                    "JPEG",
                    80,
                    0,
                    (uri) => {
                        setSelectedFile(uri);
                    },
                    "blob",
                    250,
                    250
                );
            } catch (err) {
                console.log(err);
            }
        }
        setDisabled(false);
    };
    const downloadFile = () => {
        const rows = myData.map(row => ({
            name: row.name,
            email: row.email,
            answer: row.answers
        }));
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Reachum");
        XLSX.utils.sheet_add_aoa(worksheet, [["Name", "Email", "Answer"]], {origin: "A1"});
        XLSX.writeFile(workbook, `${quest} - REACHUM_Convoze.xlsx`, {compression: true});
    }
    const psubmit = async () => {
        if (selectedFile) {
            const file = selectedFile;
            const jnfile = `aimgs/${d}aimgs`;
            await Storage.put(jnfile, file, {
                contentType: "image/*",
                progressCallback(progress) {
                    const lods = progress.loaded;
                    const done = progress.total;
                    const loadin = Math.floor((lods / done) * 100);
                    const per = loadin + "%";
                    setProcessing(per)
                    if (loadin === 100) {
                        setTimeout(() => {
                            setProcessing("Uploaded")
                            window.location.reload();
                        }, 3000);
                    }
                },
            }).then(() => {
                const aimgurl = `https://${vbucket}.s3.amazonaws.com/public/${jnfile}`;
                let mymeetnames = localStorage.getItem("vreachumnewername");
                let mymeetemails = localStorage.getItem("vreachumneweremail");
                const input = {
                    name: mymeetnames,
                    email: mymeetemails,
                    answerid: rid,
                    answers: vals,
                    aimgurl: aimgurl
                }
                API.graphql({
                    query: createAnswerslist,
                    variables: {input}
                });

            })
                .catch(err => console.log(err));
        } else {
            let mymeetnames = localStorage.getItem("vreachumnewername");
            let mymeetemails = localStorage.getItem("vreachumneweremail");
            const input = {
                answerid: rid,
                answers: vals,
                name: mymeetnames,
                email: mymeetemails,
            }
            API.graphql({
                query: createAnswerslist,
                variables: {input}
            });
        }
        setDisabled(true);
        setSubmitmessage("Submitted");
        setTimeout(() => {
            setVals("");
            setSubmitmessage("");
        }, 3000);
    }

    const studenanswers = () => {
        return myData.map((item, index) => (
            <li key={index} className="listanswers">
                <Grid container spacing={0}>
                    <Grid item xs={11}>
                        <p><span> {index + 1} </span></p>
                    </Grid>
                    <Grid item xs={1}>
                        <img src={Deletebutton} onClick={() => {
                            handleDeletethis(item.id)
                        }} width="16px" alt="delete"/>
                    </Grid>
                </Grid>
                {isnameopen ?
                    <p>{item.name}</p>
                    : ""
                }
                {isemailopen ?
                    <p>{item.email}</p>
                    : ""
                }
                <img src={item.aimgurl} className="aiimgs" onClick={async () => {
                    const input = {
                        id: rid,
                        thisanswer: item.answers,
                        thgisaimgurl: item.aimgurl
                    }
                    await API.graphql({
                        query: updateHightlightanswers,
                        variables: {input}
                    })
                    setThistext(item.answers);
                    setThisimg(item.aimgurl);
                    setShow(true)
                }}/>
                <p onClick={async () => {
                    const input = {
                        id: rid,
                        thisanswer: item.answers,
                        thgisaimgurl: item.aimgurl
                    }
                    await API.graphql({
                        query: updateHightlightanswers,
                        variables: {input}
                    })
                    setThistext(item.answers);
                    setThisimg(item.aimgurl);
                    setShow(true)
                }}>{item.answers}</p>
            </li>
        ))
    }
    const handleDeletethis = async (itemId) => {
        let text = "Are you sure you want to delete this answer? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            const input = {
                id: itemId
            };
            await API.graphql({
                query: deleteAnswerslist,
                variables: {input}
            });
        }
    };
    const handleChanges = e => {
        const thisval = e.target.value;
        setVals(thisval);
        if (thisval !== "") {
            setDisabled(false)
        }
    }
    const clearresults = async () => {
        let text = "Are you sure you want to delete all answers? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            try {
                let cl = myData.length;
                for (let i = 0; i < cl; i++) {
                    const input = {
                        id: myData[i].id
                    };
                    await API.graphql({
                        query: deleteAnswerslist,
                        variables: {input}
                    });
                }
                const inputs = {
                    id: rid
                }
                await API.graphql({
                    query: deleteHightlightanswers,
                    variables: {input: inputs}
                });
            } catch (err) {
                console.log(err)
            }
        }
    }
    return (
        <>
            <div className="answersshowroompage">
                <div className="answersroom">
                    <div className="purple1back">
                        <h2>{quest}</h2>
                    </div>
                    {isallopen ?
                        <>
                            {isOpen ?
                                <>
                                    <div>
                                        <br/>
                                        <p>Your answer:</p>
                                        <textarea onChange={handleChanges} maxLength="800"
                                                  className="textareasanswer"
                                                  placeholder="Answer" value={vals}/>
                                        <br/>
                                        <div className="convozeuploadimage">
                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <p>Upload Image:</p>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <input type="file" accept="image/*" onChange={handleFileChange}/>
                                                    {preview && (
                                                        <div>
                                                            <img
                                                                src={preview}
                                                                alt="File preview"
                                                                style={{
                                                                    maxWidth: '200px',
                                                                    maxHeight: '200px'
                                                                }} // Adjust size here
                                                            />
                                                        </div>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <p className="redthis">{error}</p>
                                    </div>
                                    <br/>
                                    <div className="fastbottom">
                                        <Grid container spacing={0}>
                                            <Grid item xs={6} className="rightsideredthis">
                                                <span className="redthis">{submitmessage} </span>
                                            </Grid>
                                            <Grid item xs={3} className="wordsleftside">
                                                <Button id="pollsubmit" onClick={psubmit} className="convosubmit"
                                                        disabled={isDisabled}>Submit</Button>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <p>{processing}</p>
                                            </Grid>
                                            <Grid item xs={1}>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {isOpens ?
                                        <>
                                            <div className="centerthisanswer">
                                                {isimage ?
                                                    <img src={thisvimg} className="thisimg"/>
                                                    : ""
                                                }
                                                <p>{thisvtext}</p>
                                            </div>
                                            <br/>
                                            <br/>
                                        </>
                                        : ""
                                    }
                                </>
                                : (
                                    <>
                                        <div className="answersroomwaiting" style={{height: containerHeight}}>
                                            <h1> {waitingresponse}</h1>
                                        </div>
                                        <div className="anserrightside">
                                            <div className="answershowleftside">
                                                <DropdownButton id="dropdown-basic-button" className="rdropmenu"
                                                                title="">
                                                    <ListGroup>
                                                        <ListGroup.Item>
                                                            {!isnameopen ?
                                                                <>
                                        <span onClick={() => {
                                            setIsnameopen(true)
                                        }} className="answersspan"> Show Name</span>
                                                                </>
                                                                :
                                                                <>
                                        <span onClick={() => {
                                            setIsnameopen(false)
                                        }} className="answersspan"> Hide Name</span>
                                                                </>
                                                            }
                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            {!isemailopen ?
                                                                <span onClick={() => {
                                                                    setIsemailopen(true)
                                                                }} className="answersspan"> Show Email</span>
                                                                :
                                                                <span onClick={() => {
                                                                    setIsemailopen(false)
                                                                }} className="answersspan"> Hide Email</span>
                                                            }
                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            <span onClick={downloadFile} className="answersspan"> Save as Spreadsheet </span>
                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            <span onClick={clearresults} className="answersspan"> Clear Responses </span>
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </DropdownButton>
                                            </div>
                                            <ul>
                                                {studenanswers()}
                                            </ul>
                                        </div>
                                    </>
                                )
                            }
                        </> : ""
                    }
                </div>
            </div>
            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="centerthis">
                        <img src={thisimg} className="thisimg"/>
                        <p>{thistext}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default Answerroom;

